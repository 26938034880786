import React from 'react';
import './SelectedSsrGrid.css';

const SelectedSsrGrid = ({ selectedSsrIds, onRemoveSelectedSsr, getImageUrl }) => {
  return (
    <div className="selected-ssr">
      {(
        <div>
          <h2>已选SSR</h2>
          <div className="ssr-selection">
            {selectedSsrIds.map((ssr, index) => (
              <img 
                key={index} 
                src={getImageUrl(ssr.id)} 
                alt={`SSR ${ssr.id}`} 
                className="ssr-image selected"
                onClick={() => onRemoveSelectedSsr(index)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectedSsrGrid;