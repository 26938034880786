import React from 'react';
import './AccountInfoList.css';

const AccountInfoList = ({ accounts, accountSsrInfo, getImageUrl }) => {
  // 对 accounts 按照 SSR 数量进行降序排序
  const sortedAccounts = accounts.sort((a, b) => {
    const aSsrCount = accountSsrInfo[a]?.length || 0;
    const bSsrCount = accountSsrInfo[b]?.length || 0;
    return bSsrCount - aSsrCount;
  });

  return (
    <div className="account-info-list">
      {sortedAccounts.length > 0 ? (
        sortedAccounts.map((accountId, index) => (
          <div key={index} className="account-block">
            <div className="account-header">
              <span className="account-id">账户 ID: {accountId}</span>
              <span className="ssr-count">SSR 数量: {accountSsrInfo[accountId]?.length || 0}</span>
            </div>
            <div className="ssr-info-container">
              {Array.isArray(accountSsrInfo[accountId]) ? (
                accountSsrInfo[accountId].map((ssr, idx) => (
                  <div key={idx} className="ssr-info">
                    <img 
                      src={getImageUrl(ssr.ssr_id)} 
                      alt={ssr.ssr_name} 
                      className="ssr-image"
                    />
                  </div>
                ))
              ) : (
                <div>加载中或未找到 SSR 信息。</div>
              )}
            </div>
          </div>
        ))
      ) : (
        <div>未找到任何账户。</div>
      )}
    </div>
  );
};

export default AccountInfoList;