import React, { createContext, useState, useContext } from 'react';
import gamesConfig from '../config';

const GameContext = createContext();

export const GameProvider = ({ children }) => {
  const [currentGame, setCurrentGame] = useState("学园偶像大师");

  const getConfig = () => gamesConfig;

  return (
    <GameContext.Provider value={{ currentGame, setCurrentGame, getConfig }}>
      {children}
    </GameContext.Provider>
  );
};

export const useGame = () => useContext(GameContext);

export { GameContext };