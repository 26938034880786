import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import SsrImageGrid from './components/SsrImageGrid';
import SelectedSsrGrid from './components/SelectedSsrGrid';
import AccountInfoList from './components/AccountInfoList';
import { GameContext } from './context/GameContext';
import { AppBar, Toolbar, Typography, Container, Box } from '@mui/material';
import { ClipLoader } from 'react-spinners';
import './App.css';

function App() {
  const { currentGame, getConfig } = useContext(GameContext);
  const [ssrInfo, setSsrInfo] = useState([]);
  const [selectedSsrIds, setSelectedSsrIds] = useState([]);
  const [accountSsrInfo, setAccountSsrInfo] = useState({});
  const [hasSearched, setHasSearched] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const itemsPerPage = 10;
  const cache = useRef({});

  const gameConfig = getConfig()[currentGame] || {};
  const shouldSeparateByType = gameConfig.shouldSeparateByType || false;

  useEffect(() => {
    const fetchSsrInfo = async () => {
      try {
        const response = await axios.get(`${gameConfig.apiBaseUrl}/ssr_info`);
        if (response.data.ssr_info) {
          setSsrInfo(response.data.ssr_info);
        } else {
          console.error('API 响应中缺少 ssr_info 字段');
        }
      } catch (error) {
        console.error('获取SSR信息时出错:', error);
      }
    };

    fetchSsrInfo();
  }, [currentGame, gameConfig.apiBaseUrl]);

  const handleSsrClick = (ssrId) => {
    setSelectedSsrIds([...selectedSsrIds, { id: ssrId, index: selectedSsrIds.length }]);
  };

  const handleRemoveSelectedSsr = (indexToRemove) => {
    setSelectedSsrIds(prevSelectedSsrIds => 
      prevSelectedSsrIds.filter((ssr, index) => index !== indexToRemove)
    );
  };

  const handleSearch = async () => {
    setCurrentPage(0);
    cache.current = {}; 
    setNoResults(false);
    setHasSearched(true);
    setIsLoading(true); // 开始加载

    await fetchAccounts(0, itemsPerPage);

    setIsLoading(false); // 加载完成
  };

  const fetchAccounts = async (page, itemsPerPage) => {
    if (selectedSsrIds.length === 0) return;

    if (cache.current[page]) {
      setAccountSsrInfo(cache.current[page].accountSsrInfo);
      setTotalPages(cache.current[page].totalPages);
      return;
    }

    try {
      const ssrIdList = selectedSsrIds.map(ssr => ssr.id).join(',');
      const response = await axios.get(`${gameConfig.apiBaseUrl}/accounts/search?ssr_ids=${ssrIdList}&page=${page + 1}&itemsPerPage=${itemsPerPage}`);
      const accounts = response.data.account_ids || [];
      const totalItems = response.data.totalItems || 0;

      const totalPages = Math.ceil(totalItems / itemsPerPage);
      setTotalPages(totalPages);

      if (accounts.length === 0) {
        setNoResults(true);
        return;
      }

      const accountSsrInfo = await Promise.all(
        accounts.map(async (accountId) => {
          const accountResponse = await axios.get(`${gameConfig.apiBaseUrl}/accounts/${accountId}/ssr`);
          const ssrList = accountResponse.data.ssr_list || [];
          return { accountId, ssrList };
        })
      );

      const accountSsrInfoObj = accountSsrInfo.reduce((acc, item) => {
        acc[item.accountId] = item.ssrList;
        return acc;
      }, {});

      cache.current[page] = {
        accountSsrInfo: accountSsrInfoObj,
        totalPages,
      };

      setAccountSsrInfo(accountSsrInfoObj);
    } catch (error) {
      console.error('获取账户数据时出错:', error);
    }
  };

  const handlePageChange = async ({ selected }) => {
    setCurrentPage(selected);
    setIsLoading(true); // 开始加载

    await fetchAccounts(selected, itemsPerPage);

    setIsLoading(false); // 加载完成
  };

  const getImageUrl = (ssrId) => {
    return `${gameConfig.imageBaseUrl}/${ssrId}.webp`;
  };

  return (
    <div className="App">
      <AppBar position="static" color="primary">
        <Toolbar>
          <Container maxWidth="md">
            <Box textAlign="center" py={2}>
              <Typography variant="h5" component="h1" gutterBottom>
                {currentGame}自选号
              </Typography>
              <Typography variant="body1" component="h2">
                选好发账号 id 给客服
              </Typography>
              <Typography variant="body2" component="h3">
              <Box>
                <a href="https://shop503912220.m.taobao.com/?spm=a1z10.1-c-s.0.0.2cf2431bcoixW0" className="custom-link">
                  淘宝 : 曼波电玩
                </a>
              </Box>
              </Typography>
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
      <SsrImageGrid 
        ssrInfo={ssrInfo}
        onSsrClick={handleSsrClick}
        getImageUrl={getImageUrl}
        shouldSeparateByType={shouldSeparateByType}
      />

      <SelectedSsrGrid 
        selectedSsrIds={selectedSsrIds}
        onRemoveSelectedSsr={handleRemoveSelectedSsr}
        getImageUrl={getImageUrl}
      />

      <button className="search-button" onClick={handleSearch}>搜索</button>

      {isLoading ? (
        <div className="loading">
          <ClipLoader color={"#123abc"} loading={isLoading} size={50} />
          <div>正在加载，请稍候...</div>
        </div>
      ) : (
        hasSearched && (
          noResults ? <div>没有找到相关账户。</div> : (
            <>
              <AccountInfoList 
                accounts={Object.keys(accountSsrInfo)} // 传递 account IDs 数组
                accountSsrInfo={accountSsrInfo}      // 传递 accountSsrInfo 对象
                getImageUrl={getImageUrl}
              />
              <ReactPaginate
                previousLabel={'上一页'}
                nextLabel={'下一页'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                forcePage={currentPage}
              />
            </>
          )
        )
      )}
    </div>
  );
}

export default App;