import React from 'react';
import './SsrImageGrid.css'; // 引入样式文件

const SsrImageGrid = ({ ssrInfo, onSsrClick, getImageUrl, shouldSeparateByType }) => {
  if (shouldSeparateByType) {
    const aidoruSsr = ssrInfo.filter(ssr => ssr.type === 'Aidoru');
    const spSsr = ssrInfo.filter(ssr => ssr.type === 'Sp');

    return (
      <div>
        <div className="ssr-selection">
        点击图片选择SSR
          <h2>Aidoru SSR</h2>
          {aidoruSsr.map(ssr => (
            <img
              key={ssr.id}
              src={getImageUrl(ssr.id)}
              alt={`SSR ${ssr.id}`}
              className="ssr-image"
              onClick={() => onSsrClick(ssr.id)}
            />
          ))}
        </div>
        
        <div className="ssr-selection">
          <h2>Sp SSR</h2>
          {spSsr.map(ssr => (
            <img
              key={ssr.id}
              src={getImageUrl(ssr.id)}
              alt={`SSR ${ssr.id}`}
              className="ssr-image"
              onClick={() => onSsrClick(ssr.id)}
            />
          ))}
        </div>
      </div>
    );
  } else {
    // 不按类型区分，只显示所有 SSR 图片
    return (
      <div className="ssr-selection">
        {ssrInfo.map(ssr => (
          <img
            key={ssr.id}
            src={getImageUrl(ssr.id)}
            alt={`SSR ${ssr.id}`}
            className="ssr-image"
            onClick={() => onSsrClick(ssr.id)}
          />
        ))}
      </div>
    );
  }
};

export default SsrImageGrid;