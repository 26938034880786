const gamesConfig = {
  "学园偶像大师": {
    apiBaseUrl: 'https://gms-api.manbo.win/api/gakumasu',
    imageBaseUrl: '/images/gakumasu',
    shouldSeparateByType: true,
  },
  game2: {
    apiBaseUrl: 'http://localhost:3000/api/game2',
    imageBaseUrl: '/images/game2',
    shouldSeparateByType: false,
  },
  // 将来可以添加更多游戏配置
};

export default gamesConfig;
  